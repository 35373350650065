
/*
    `i18next` plugin that replaces simple TT2 expressions in copydeck values.
    https://www.i18next.com/misc/creating-own-plugins#post-processor

    Supports running on both the server and the browser.
*/

const
    TT2_TAG_REGEX = /<%\s+(.+?)\s+%>/g,
    COPYDECK_REF_REGEX = /c\.([\w\d]+)\.([.\w\d]+)/;

module.exports = {
    type: 'postProcessor',
    name: 'tt2Tags',
    process (value, key, options, translator) {
        return value.replace(
            TT2_TAG_REGEX,
            (match, expression) => {
                if (!COPYDECK_REF_REGEX.test(expression)) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(`stripping unsupported TT2 expression in ${key}`, { match });
                    }
                    return ' ';
                }

                return expression.replace(
                    COPYDECK_REF_REGEX,
                    (match, namespace, key) => translator.translate(
                        `${namespace}:${key}`,
                        options,
                    ),
                );
            },
        );
    },
};
